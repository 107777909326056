<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">{{$t('message.procedural_list')}}</h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
          w-reset
        "
      >
        <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Процедурный лист"
          name="Процедурный_лист.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete"
            >Excel</el-button
          >
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'doctorCabinet'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patient_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.day"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>

          <!-- <crm-th
            :column="columns.doctor_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th> -->
          <crm-th
            :column="columns.condition_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.partner_doctor_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <!-- <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th> -->
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.patient_id.show">
            <el-input
              size="mini"
              v-model="filterForm.patient_id"
              :placeholder="columns.patient_id.title"
              clearable
            ></el-input>
            <!-- <el-select filterable clearable :placeholder="columns.patient_id.title" size="mini"
                      v-model="filterForm.patient_id">
                      <el-option v-for="item in list" :key="'patient'+item.patient.id" :label="item.patient.first_name + ' ' + item.patient.surname"
                          :value="item.patient.id"></el-option>
            </el-select>-->
          </th>
          <th v-if="columns.day.show">
            <el-date-picker
              v-model="filterForm.day"
              :placeholder="columns.day.title"
              size="mini"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
            ></el-date-picker>
          </th>

          <!-- <th v-if="columns.doctor_id.show">
            <el-input
              size="mini"
              v-model="filterForm.doctor_id"
              disabled
              :placeholder="columns.doctor_id.title"
              clearable
            ></el-input>
          </th> -->
          <th v-if="columns.condition_id.show">
            <el-select
              filterable
              clearable
              :placeholder="columns.condition_id.title"
              size="mini"
              v-model="filterForm.condition_id"
            >
              <el-option
                v-for="item in conditions"
                :key="'condition' + item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.partner_doctor_id.show">
            <el-select
              filterable
              clearable
              :placeholder="columns.partner_doctor_id.title"
              size="mini"
              v-model="filterForm.partner_doctor_id"
            >
              <el-option
                v-for="item in partner_doctors"
                :key="'partner_doctor' + item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </th>
          <!-- <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            ></el-date-picker>
          </th> -->
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="patient in list" :key="patient.id">
          <td v-if="columns.id.show">{{ patient.id }}</td>
          <td v-if="columns.patient_id.show">
            {{ patient.patient.surname ? patient.patient.surname : "" }}
            {{ patient.patient.first_name }}
          </td>
          <td v-if="columns.day.show">{{ patient.day }}</td>
          <!-- <td v-if="columns.doctor_id.show">
            {{ patient.doctor ? patient.doctor.patronymic : "" }}
            {{ patient.doctor ? patient.doctor.surname : "" }}
          </td> -->
          <td v-if="columns.condition_id.show">
            {{ patient.condition ? patient.condition.name : "" }}
          </td>
          <td v-if="columns.partner_doctor_id.show">
            {{ patient.partner_doctor ? patient.partner_doctor.surname : "" }}
            {{ patient.partner_doctor ? patient.partner_doctor.name : "" }}
          </td>
          <!-- <td v-if="columns.created_at.show">
            {{ patient.created_at }}
          </td> -->
          <td v-if="columns.settings.show" class="settings-td">
            <div class="za_btn">
              <!-- <crm-setting-dropdown :model="patient" name="typeInjuries" :actions="actions" @edit="edit">
              </crm-setting-dropdown> -->
              <el-button
                v-if="!button(patient.id)"
                @click="createModal(patient.id)"
                type="primary"
                icon="el-icon-edit"
                >{{ $t("message.create") }}</el-button
              >
            </div>

            <div class="za_btn">
              <el-button
                v-if="button(patient.id)"
                @click="edit(patient)"
                type="seccess"
                icon="el-icon-edit"
                >{{ $t("message.update") }}</el-button
              >
            </div>
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      :visible.sync="drawerCreateCoculation"
      size="85%" :wrapperClosable="false"
      :drawer="drawerCreateCoculation"
      @open="reopenCreate = true"
      @close="reloadIfChanged('reopenCreate')"
      @closed="emptyModel"
    >
      <div>
        <crm-create
          :reloadModel="reopenCreate"
          @open="reopenCreate = true"
          @c-close="closeDrawer"
          ref="createDrawer"
          @button="buttons"
        ></crm-create>
      </div>
    </el-drawer>

    <el-drawer
      size="85%" :wrapperClosable="false"
      :visible.sync="drawer.update.status"
      :ref="drawer.update.name"
      @opened="drawerOpened(drawer.update.component)"
      @closed="drawerClosed(drawer.update.component)"
    >
      <crm-update
        :selected="selectedModel"
        :ref="drawer.update.component"
        :drawer-name="drawer.update.name"
      >
      </crm-update>
    </el-drawer>
    <!-- <el-drawer
      title="Изменить тег"
      :visible.sync="drawerUpdate"
      size="85%"
      :drawer="drawerUpdate"
      @open="reopenUpdate = true"
      @close="reopenUpdate = false"
      @closed="emptyModel"
    >
      <div>
        <crm-update
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="reloadIfChanged('drawerUpdate')"
          @closed="emptyModel"
        ></crm-update>
      </div>
    </el-drawer> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [list, drawer],
  name: "PatientServiceList",
  components: {
    CrmCreate,
    CrmUpdate,
  },
  data() {
    return {
      drawerCreateCoculation: false,
      drawerUpdate: false,
      selectedModel: {},
      afterCreate: false,
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate",
        },
      },
      filterForm: {},
      reopenCreate: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "patientMedicine/patient_list",
      columns: "doctorCabinet/columns",
      pagination: "doctorCabinet/pagination",
      filter: "doctorCabinet/filter",
      sort: "doctorCabinet/sort",
      // patient_medicine: "patientMedicine/patient_medicine",
      conditions: "conditions/list",
      partner_doctors: "partnerDoctor/list",
      lists: "patientMedicine/patient_medicine",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  async mounted() {
    if (this.lists && this.lists.length === 0) await this.loadPatient();
    await this.controlExcelData();
    if (this.conditions && this.conditions.length === 0)
      await this.loadConditions({ doctor: true });
    if (this.partner_doctors && this.partner_doctors.length === 0)
      await this.loadPartnerDoctors({ column: 'name', order: 'asc'});
  },
  methods: {
    ...mapActions({
      updateList: "patientMedicine/patientListMedicine",
      showModel: "orders/relationShow",
      showMedicine: "patientMedicine/patientMedicineShowOrder",
      updateSort: "doctorCabinet/updateSort",
      updateFilter: "doctorCabinet/updateFilter",
      updateColumn: "doctorCabinet/updateColumn",
      updatePagination: "doctorCabinet/updatePagination",
      empty: "doctorCabinet/empty",
      refreshData: "doctorCabinet/refreshData",
      loadPartnerDoctors: "partnerDoctor/index",
      loadPatient: "patientMedicine/patientMedicineShow",
      loadConditions: "conditions/iventory",
    }),
    buttons(val) {
       this.lists.push(val)
    },
    button(data) {
      return this.lists.filter((val) => val.order_id == data).length
    },
    async fetchData() {
      const query = {
        doctor: "patsents",
        ...this.filterForm,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData();
    },
    async show(id) {
      this.$router.push("/doctor-cabinet/conclusions/" + id);
    },
    async createModal(id) {
      this.$loadingCursor("wait");
      const query = { id: id, relation: true };
      this.showModel(query)
        .then((res) => {
          this.drawerCreateCoculation = true;
          this.$loadingCursor("default");
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    edit(model) {
      this.selectedModel = model;
      this.drawer.update.status = true;
    },
    // async updateModal(id) {
    //   this.$loadingCursor("wait");
    //   const query = { id: id, relation: true };
    //   this.showMedicine(id)
    //     .then((res) => {
    //       this.drawerUpdate = true;
    //       this.$loadingCursor("default");
    //     })
    //     .catch((err) => {
    //       this.$loadingCursor("default");
    //     });
    // },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
<style lang="scss" scoped>
.router_style {
  td {
    padding: 0 !important;
    margin: 0px !important;
    a {
      display: block;
      padding: 0.3rem;
      color: #303030;
      font-weight: 200 !important;
      font-size: 14px !important;
    }
  }
}
</style>



